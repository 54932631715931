import React, {useEffect, useState} from "react";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import StylesLayout from "../components/Layout.module.scss";
import TeamList from "../components/TeamList";
import {getDOM, getOffset} from "../helper/getDOM";

const AboutPage = () => {
    const [darkThem, setDarkThem] = useState(true);

    useEffect(() => {
        window.addEventListener('scroll', scrollEvent)
        return () => {
            window.removeEventListener('scroll', scrollEvent)
        }
    })

    function scrollEvent(e: Event) {
        let windowsHeight = window.innerHeight;
        let startThem = getOffset(document.getElementById("startDarkThem")).top - windowsHeight / 2
        let endThem = getOffset(document.getElementById("endDarkThem")).top - windowsHeight / 2
        let scrollPosition = getDOM(e).sy;
        scrollPosition > startThem && scrollPosition < endThem ? setDarkThem(true) : setDarkThem(false)
    }

    return (
        <Layout lightThem={darkThem}>
            <SEO title="Full stack game development"
                 description="WECAN distributed ledger game development team leverages emerging technology to create gaming
             applications utilizing EOSIo software."/>
            <div className={StylesLayout.content}>
                <TeamList/>
            </div>
        </Layout>
    );
}

export default AboutPage;
