import Styles from "./Buttons.module.scss"
import React from "react";
import classNames from "classnames";

export enum EButtonType {
    PRIMARY,
    SECONDARY,
    THIRD
}

const Buttons = (
    {
        text,
        type,
        icon
    }: {
        text: string,
        type: EButtonType,
        icon?: boolean
    }) => {

    return (
        <button className={classNames(Styles.button, getTypeClass(type), {[Styles.withIcon]: icon})}>
            {icon && <div className={Styles.icon}/>}
            {text}
        </button>
    )
};

export function getTypeClass(type: EButtonType) {
    switch (type) {
        case EButtonType.PRIMARY:
            return Styles.primary;
        case EButtonType.SECONDARY:
            return Styles.secondary;
        case EButtonType.THIRD:
            return Styles.third;
    }
}

export default Buttons