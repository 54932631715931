import React from "react"
import Styles from "./TeamTile.module.scss"
import Icon, {EIconSize, EIconType} from "./Ui/Icon";
import Buttons, {EButtonType} from "./Ui/Buttons";
import {ITeamTitle} from "../date/TeamList";

const TeamTile = (
    {
        teamTitle,
    }: {
        teamTitle: ITeamTitle

    }) => (
    <div className={Styles.tile}>
        <div className={Styles.image}>
            <img src={teamTitle.image} alt={"Team member"}/>
        </div>
        {!teamTitle.more ?
            <>
                <div className={Styles.name}>{teamTitle.name}</div>
                <div className={Styles.skill}>{teamTitle.skill}</div>
                <a href={teamTitle.link} target={"_target"}>
                    <Icon type={EIconType.LINKED_LOGO} size={EIconSize.BIG}/>
                </a>
            </>
            :
            <div className={Styles.btn}>
                <Buttons text={'MORE WECANs'} type={EButtonType.PRIMARY}/>
            </div>
        }
    </div>
)

export default TeamTile
