import React from "react"
import Styles from "./TeamList.module.scss"
import H2 from "./Ui/H2";
import {L} from "../l10n/L10n";
import Ps from "./Ui/Ps";
import TeamTile from "./TeamTile";
import {TeamListData} from "../date/TeamList";

const TeamList = () => {
    return (
        <div className={Styles.team} id={"teams"}>
            <div className={Styles.teamText}>
                <H2>{L.team.title}</H2>
                <Ps>{L.team.description}</Ps>
            </div>
            <div className={Styles.teamList}>
                {TeamListData.map((teamMember, index) =>
                    <TeamTile
                        teamTitle={teamMember}
                        key={teamMember.name + index}
                    />)}
            </div>
            <div id={"endDarkThem"}/>
        </div>
    )
}

export default TeamList
