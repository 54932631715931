import julja_liashchynska from "../images/teams/julja_liashchynska.png"
import zhenia_slobodianiuk from "../images/teams/zhenia_slobodianiuk.png"
import pavlo_zubrytskyi from "../images/teams/pavlo_zubrytskyi.png"
import pavlo_sapeha from "../images/teams/pavlo_sapeha.png"
import nazar_rubashnyy from "../images/teams/nazar_rubashnyy.png"
import max_karkach from "../images/teams/max_karkach.png"
import pavlo_chervinskyy from "../images/teams/pavlo_chervinskyy.png"
import andrew_skochylyas from "../images/teams/andrew_skochylyas.png"
import yaroslav_kihtan from "../images/teams/yaroslav_kihtan.png"
import andriy_maslijevych from "../images/teams/andriy_maslijevych.png"
import nazar_chervinskyy from "../images/teams/nazar_chervinskyy.png"
import aliona_bohomol from "../images/teams/aliona_bohomol.png"
import olha_sereda from "../images/teams/olha_sereda.png"
// import teamMascot from "../images/landing/img_teamMascot.png"

export interface ITeamTitle {
    image: string,
    name: string,
    skill: string,
    link: string
    more?: boolean
}

export const TeamListData: ITeamTitle[] = [{
    image: nazar_chervinskyy,
    name: "NAZAR CHERVINSKYY",
    skill: "CO-FOUNDER & CEO",
    link: "https://www.linkedin.com/in/nazar-chervinskiy/"
}, {
    image: andriy_maslijevych,
    name: "ANDRII MASLIIEVYCH",
    skill: "CO-FOUNDER & FULL STACK DAPP DEVELOPER",
    link: "https://www.linkedin.com/in/andriy-maslijevych/"
}, {
    image: yaroslav_kihtan,
    name: "YAROSLAV KIHTAN",
    skill: "CTO",
    link: "https://www.linkedin.com/in/yaroslav-kihtan-7941aa81/"
},{
    image: andrew_skochylyas,
    name: "ANDREW SKOCHYLYAS",
    skill: "FULL STACK DAPP DEVELOPER",
    link: "https://www.linkedin.com/in/andrew-skochilias/"
},{
    image: pavlo_chervinskyy,
    name: "PAVLO CHERVINSKYY",
    skill: "FULL STACK DAPP DEVELOPER",
    link: "https://www.linkedin.com/in/pavlo-chervinskyi/"
},{
    image: max_karkach,
    name: "MAX KARKACH",
    skill: "ART DIRECTOR",
    link: "https://www.linkedin.com/in/maxkarkach"
},{
    image: aliona_bohomol,
    name: "ALIONA BOHOMOL",
    skill: "UI/UX DESIGNER",
    link: "https://ua.linkedin.com/in/aliona-bohomol-664515158"
}, {
    image: nazar_rubashnyy,
    name: "NAZAR RUBASHNYY",
    skill: "PROJECT MANAGER",
    link: "https://www.linkedin.com/in/nazarrubashnyy/"
}, {
    image: pavlo_sapeha,
    name: "PAVLO SAPEHA",
    skill: "BUSINESS DEVELOPMENT DIRECTOR",
    link: "https://www.linkedin.com/in/pavlo-sapeha-000694202"
}, {
    image: zhenia_slobodianiuk,
    name: "ZHENIA SLOBODIANIUK",
    skill: "CHIEF MARKETING OFFICER",
    link: "https://www.linkedin.com/in/zhenia-slobodianiuk-096a2114b/"
}, {
    image: julja_liashchynska,
    name: "JULJA LIASHCHYNSKA",
    skill: "CONTENT WRITER",
    link: "https://www.linkedin.com/in/julja-liashchynska-31658513a/"
},
    {
    image: pavlo_zubrytskyi,
    name: "PAVLO ZUBRYTSKIY",
    skill: "SITE RELIABILITY ENGINEER",
    link: "https://www.linkedin.com/in/pavlo-zubrytskiy/",
},
{
    image: olha_sereda,
    name: "Olha Sereda",
    skill: "UI/UX DESIGNER",
    link: "https://www.linkedin.com/in/olha-sereda-4734101a9/",
},
];
